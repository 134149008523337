import React, { useState, useEffect } from 'react';
import {
  Box,
  Container,
  Typography,
  Tabs,
  Tab,
  Button,
  Dialog,
  IconButton,
  Alert,
} from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import { collection, query, getDocs, addDoc, updateDoc, deleteDoc, doc, where } from 'firebase/firestore';
import { db } from '../firebase';
import MarketingDashboard from '../components/marketing/MarketingDashboard';
import CampaignList from '../components/marketing/CampaignList';
import CampaignBuilder from '../components/marketing/CampaignBuilder';
import CampaignAnalytics from '../components/marketing/CampaignAnalytics';

const MarketingPage = () => {
  const [currentTab, setCurrentTab] = useState(0);
  const [campaigns, setCampaigns] = useState([]);
  const [analytics, setAnalytics] = useState(null);
  const [openCampaignBuilder, setOpenCampaignBuilder] = useState(false);
  const [selectedCampaign, setSelectedCampaign] = useState(null);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  useEffect(() => {
    fetchCampaigns();
    fetchAnalytics();
  }, []);

  const fetchCampaigns = async () => {
    try {
      const campaignsRef = collection(db, 'marketing_campaigns');
      const campaignsSnapshot = await getDocs(campaignsRef);
      const campaignsList = campaignsSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setCampaigns(campaignsList);
    } catch (err) {
      setError('Failed to fetch campaigns');
      console.error('Error fetching campaigns:', err);
    }
  };

  const fetchAnalytics = async () => {
    try {
      const analyticsRef = collection(db, 'marketing_analytics');
      const analyticsQuery = query(analyticsRef, where('type', '==', 'overview'));
      const analyticsSnapshot = await getDocs(analyticsQuery);
      
      if (!analyticsSnapshot.empty) {
        setAnalytics(analyticsSnapshot.docs[0].data());
      } else {
        // Initialize default analytics if none exist
        const defaultAnalytics = {
          activeCampaigns: 0,
          totalSubscribers: 0,
          avgOpenRate: 0,
          pushNotifications: 0,
          recentCampaigns: [],
          segmentPerformance: []
        };
        setAnalytics(defaultAnalytics);
      }
    } catch (err) {
      setError('Failed to fetch analytics');
      console.error('Error fetching analytics:', err);
    }
  };

  const handleSaveCampaign = async (campaignData) => {
    try {
      if (selectedCampaign) {
        // Update existing campaign
        await updateDoc(doc(db, 'marketing_campaigns', selectedCampaign.id), campaignData);
        setSuccess('Campaign updated successfully');
      } else {
        // Create new campaign
        await addDoc(collection(db, 'marketing_campaigns'), {
          ...campaignData,
          createdAt: new Date(),
          status: 'draft'
        });
        setSuccess('Campaign created successfully');
      }
      fetchCampaigns();
      setOpenCampaignBuilder(false);
      setSelectedCampaign(null);
    } catch (err) {
      setError('Failed to save campaign');
      console.error('Error saving campaign:', err);
    }
  };

  const handleDeleteCampaign = async (campaignId) => {
    if (window.confirm('Are you sure you want to delete this campaign?')) {
      try {
        await deleteDoc(doc(db, 'marketing_campaigns', campaignId));
        setSuccess('Campaign deleted successfully');
        fetchCampaigns();
      } catch (err) {
        setError('Failed to delete campaign');
        console.error('Error deleting campaign:', err);
      }
    }
  };

  const handleEditCampaign = (campaign) => {
    setSelectedCampaign(campaign);
    setOpenCampaignBuilder(true);
  };

  const tabContent = [
    {
      label: 'Dashboard',
      component: <MarketingDashboard analytics={analytics} />
    },
    {
      label: 'Campaigns',
      component: (
        <CampaignList
          campaigns={campaigns}
          onEdit={handleEditCampaign}
          onDelete={handleDeleteCampaign}
        />
      )
    },
    {
      label: 'Analytics',
      component: <CampaignAnalytics campaign={selectedCampaign} />
    }
  ];

  return (
    <Container maxWidth="xl">
      {error && (
        <Alert 
          severity="error" 
          onClose={() => setError(null)}
          sx={{ mb: 2 }}
        >
          {error}
        </Alert>
      )}
      
      {success && (
        <Alert 
          severity="success" 
          onClose={() => setSuccess(null)}
          sx={{ mb: 2 }}
        >
          {success}
        </Alert>
      )}

      <Box sx={{ mb: 4 }}>
        <Typography variant="h4" component="h1" gutterBottom>
          Marketing
        </Typography>
        <Tabs 
          value={currentTab} 
          onChange={(e, newValue) => setCurrentTab(newValue)}
          sx={{ borderBottom: 1, borderColor: 'divider' }}
        >
          {tabContent.map((tab, index) => (
            <Tab key={index} label={tab.label} />
          ))}
        </Tabs>
      </Box>

      {tabContent[currentTab].component}

      {/* Campaign Builder Dialog */}
      <Dialog 
        open={openCampaignBuilder}
        onClose={() => {
          setOpenCampaignBuilder(false);
          setSelectedCampaign(null);
        }}
        maxWidth="lg"
        fullWidth
      >
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', p: 2 }}>
          <Typography variant="h6">
            {selectedCampaign ? 'Edit Campaign' : 'Create New Campaign'}
          </Typography>
          <IconButton 
            onClick={() => {
              setOpenCampaignBuilder(false);
              setSelectedCampaign(null);
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
        <Box sx={{ p: 2 }}>
          <CampaignBuilder 
            campaign={selectedCampaign}
            onSave={handleSaveCampaign}
          />
        </Box>
      </Dialog>
    </Container>
  );
};

export default MarketingPage; 
import React, { useState } from 'react';
import { 
  Box, 
  Paper,
  List, 
  ListItem, 
  ListItemText, 
  ListItemAvatar,
  ListItemSecondaryAction,
  Typography, 
  TextField, 
  Button,
  Avatar,
  IconButton,
  Divider,
  Alert,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import {
  Delete as DeleteIcon,
  ContentCopy as CopyIcon,
  Link as LinkIcon,
  Check as CheckIcon,
} from '@mui/icons-material';
import { collection, addDoc, deleteDoc, doc, serverTimestamp } from 'firebase/firestore';
import { db } from '../../firebase';
import moment from 'moment-timezone';

const MessageBoard = ({ messages = [], eventId, publicUrl }) => {
  const [newMessage, setNewMessage] = useState('');
  const [selectedMessage, setSelectedMessage] = useState(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [linkCopied, setLinkCopied] = useState(false);

  const handleSendMessage = async () => {
    if (!newMessage.trim()) return;

    try {
      await addDoc(collection(db, 'eventMessages'), {
        eventId,
        content: newMessage.trim(),
        createdAt: serverTimestamp(),
        userName: 'Admin', // Or get from auth context
        userId: 'admin', // Or get from auth context
        isAdmin: true
      });
      setNewMessage('');
    } catch (error) {
      console.error('Error sending message:', error);
    }
  };

  const handleDeleteMessage = async () => {
    if (!selectedMessage) return;

    try {
      await deleteDoc(doc(db, 'eventMessages', selectedMessage.id));
      setDeleteDialogOpen(false);
      setSelectedMessage(null);
    } catch (error) {
      console.error('Error deleting message:', error);
    }
  };

  const handleCopyLink = () => {
    navigator.clipboard.writeText(publicUrl);
    setLinkCopied(true);
    setTimeout(() => setLinkCopied(false), 2000);
  };

  return (
    <Box>
      {/* Public Link Section */}
      <Paper sx={{ p: 2, mb: 3 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <Typography variant="h6" sx={{ display: 'flex', alignItems: 'center' }}>
            <LinkIcon sx={{ mr: 1 }} /> Public Message Board
          </Typography>
          <Box>
            <TextField
              size="small"
              value={publicUrl}
              sx={{ mr: 1 }}
              InputProps={{
                readOnly: true,
              }}
            />
            <Tooltip title={linkCopied ? "Copied!" : "Copy Link"}>
              <IconButton onClick={handleCopyLink} color={linkCopied ? "success" : "default"}>
                {linkCopied ? <CheckIcon /> : <CopyIcon />}
              </IconButton>
            </Tooltip>
          </Box>
        </Box>
      </Paper>

      {/* Messages List */}
      <Paper sx={{ mb: 3 }}>
        <List>
          {messages.length === 0 ? (
            <ListItem>
              <ListItemText 
                primary="No messages yet"
                secondary="Messages will appear here once users start chatting"
              />
            </ListItem>
          ) : (
            messages.map((message, index) => (
              <React.Fragment key={message.id}>
                <ListItem alignItems="flex-start">
                  <ListItemAvatar>
                    <Avatar>{message.userName?.[0] || 'U'}</Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Typography component="span" variant="subtitle2">
                          {message.userName || 'Unknown User'}
                        </Typography>
                        {message.isAdmin && (
                          <Typography 
                            component="span" 
                            variant="caption" 
                            sx={{ ml: 1, bgcolor: 'primary.main', color: 'white', px: 1, borderRadius: 1 }}
                          >
                            Admin
                          </Typography>
                        )}
                      </Box>
                    }
                    secondary={
                      <>
                        <Typography component="span" variant="body2" color="text.primary">
                          {message.content}
                        </Typography>
                        <Typography component="div" variant="caption" color="text.secondary">
                          {moment(message.createdAt.toDate()).format('MMM D, YYYY h:mm A')}
                        </Typography>
                      </>
                    }
                  />
                  <ListItemSecondaryAction>
                    <IconButton onClick={() => setSelectedMessage(message)}>
                      <DeleteIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
                {index < messages.length - 1 && <Divider />}
              </React.Fragment>
            ))
          )}
        </List>
      </Paper>

      {/* Message Management Dialog */}
      <Dialog open={deleteDialogOpen} onClose={() => setDeleteDialogOpen(false)}>
        <DialogTitle>Delete Message</DialogTitle>
        <DialogContent>
          Are you sure you want to delete this message?
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteDialogOpen(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDeleteMessage} color="primary" autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      {/* Message Input Form */}
      <Box component="form" onSubmit={handleSendMessage} sx={{ display: 'flex', gap: 1 }}>
        <TextField
          fullWidth
          variant="outlined"
          placeholder="Type a message..."
          value={newMessage}
          onChange={(e) => setNewMessage(e.target.value)}
        />
        <Button type="submit" variant="contained">
          Send
        </Button>
      </Box>
    </Box>
  );
};

export default MessageBoard; 
import React from 'react';
import { 
  Box, 
  Grid, 
  Paper, 
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Divider 
} from '@mui/material';
import {
  PeopleAlt,
  Message,
  ConfirmationNumber,
  TrendingUp
} from '@mui/icons-material';
import moment from 'moment-timezone';

const EventAnalytics = ({ analytics, attendees = [], messages = [] }) => {
  const stats = [
    {
      title: 'Total Attendees',
      value: analytics?.totalAttendees || 0,
      icon: <PeopleAlt />,
      color: '#1976d2'
    },
    {
      title: 'Tickets Sold',
      value: analytics?.ticketsSold || 0,
      icon: <ConfirmationNumber />,
      color: '#2e7d32'
    },
    {
      title: 'Messages',
      value: analytics?.messageCount || 0,
      icon: <Message />,
      color: '#ed6c02'
    },
    {
      title: 'Engagement Rate',
      value: `${analytics?.engagementRate || 0}%`,
      icon: <TrendingUp />,
      color: '#9c27b0'
    }
  ];

  const recentActivity = Array.isArray(messages) 
    ? [...messages]
        .sort((a, b) => (b.createdAt?.toDate?.() || 0) - (a.createdAt?.toDate?.() || 0))
        .slice(0, 5)
    : [];

  return (
    <Box>
      <Grid container spacing={3}>
        {stats.map((stat) => (
          <Grid item xs={12} sm={6} md={3} key={stat.title}>
            <Paper 
              sx={{ 
                p: 2,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                height: '100%'
              }}
            >
              <Avatar 
                sx={{ 
                  bgcolor: stat.color,
                  width: 56,
                  height: 56,
                  mb: 1
                }}
              >
                {stat.icon}
              </Avatar>
              <Typography variant="h4" component="div">
                {stat.value}
              </Typography>
              <Typography color="text.secondary" variant="body2">
                {stat.title}
              </Typography>
            </Paper>
          </Grid>
        ))}
      </Grid>

      <Grid container spacing={3} sx={{ mt: 3 }}>
        <Grid item xs={12} md={6}>
          <Paper sx={{ p: 2 }}>
            <Typography variant="h6" gutterBottom>
              Recent Activity
            </Typography>
            <List>
              {recentActivity.map((activity, index) => (
                <React.Fragment key={activity.id || index}>
                  <ListItem alignItems="flex-start">
                    <ListItemAvatar>
                      <Avatar>
                        <Message />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={activity.userName || 'Unknown User'}
                      secondary={
                        <>
                          <Typography component="span" variant="body2" color="text.secondary">
                            {activity.createdAt?.toDate 
                              ? moment(activity.createdAt.toDate()).fromNow()
                              : 'Unknown time'}
                          </Typography>
                          <br />
                          {activity.content || 'No content'}
                        </>
                      }
                    />
                  </ListItem>
                  {index < recentActivity.length - 1 && <Divider variant="inset" />}
                </React.Fragment>
              ))}
            </List>
          </Paper>
        </Grid>

        <Grid item xs={12} md={6}>
          <Paper sx={{ p: 2 }}>
            <Typography variant="h6" gutterBottom>
              Attendee Distribution
            </Typography>
            <Box sx={{ mt: 2 }}>
              <Typography variant="body1">
                RSVP: {Array.isArray(attendees) ? attendees.filter(a => a.ticketType === 'RSVP').length : 0}
              </Typography>
              <Typography variant="body1">
                Paid Tickets: {Array.isArray(attendees) ? attendees.filter(a => a.ticketType !== 'RSVP').length : 0}
              </Typography>
              <Typography variant="body1">
                Checked In: {Array.isArray(attendees) ? attendees.filter(a => a.checkedIn).length : 0}
              </Typography>
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default EventAnalytics; 
import React, { useState, useEffect } from 'react';
import { 
  Box, 
  Typography, 
  Card, 
  CardContent, 
  Grid,
  Button 
} from '@mui/material';
import { collection, query, orderBy, limit, getDocs } from 'firebase/firestore';
import { db } from '../../firebase';
import { useNavigate } from 'react-router-dom';
import moment from 'moment-timezone';

const RecentEvents = () => {
  const [events, setEvents] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchRecentEvents = async () => {
      try {
        const q = query(
          collection(db, 'events'),
          orderBy('date.start', 'desc'),
          limit(4)
        );
        const querySnapshot = await getDocs(q);
        const eventList = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
          name: doc.data().title
        }));
        setEvents(eventList);
      } catch (error) {
        console.error('Error fetching recent events:', error);
      }
    };

    fetchRecentEvents();
  }, []);

  const formatEventDate = (date) => {
    if (!date) return '';
    const eventDate = moment(date.startDate);
    return eventDate.format('MMM D, YYYY');
  };

  return (
    <Box>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
        <Typography variant="h6">Recent Events</Typography>
        <Button 
          color="primary" 
          onClick={() => navigate('/events')}
        >
          View All
        </Button>
      </Box>
      <Grid container spacing={2}>
        {events.map((event) => (
          <Grid item xs={12} sm={6} key={event.id}>
            <Card 
              sx={{ 
                cursor: 'pointer',
                '&:hover': { boxShadow: 3 }
              }}
              onClick={() => navigate(`/events/${event.id}`)}
            >
              <CardContent>
                <Typography variant="h6" noWrap>
                  {event.name}
                </Typography>
                <Typography color="textSecondary">
                  {formatEventDate(event.date)}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  {event.venue} {event.city && `- ${event.city}`}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default RecentEvents;

import React from 'react';
import {
  Box,
  Paper,
  Typography,
  Divider,
} from '@mui/material';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import AppleIcon from '@mui/icons-material/Apple';

const NotificationPreview = ({ message }) => {
  return (
    <Box>
      {/* iOS Preview */}
      <Box sx={{ mb: 3 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
          <AppleIcon sx={{ mr: 1 }} />
          <Typography variant="subtitle1">iOS Preview</Typography>
        </Box>
        <Paper 
          sx={{ 
            p: 2, 
            bgcolor: '#f5f5f5',
            borderRadius: 2,
            maxWidth: '300px'
          }}
        >
          <Typography variant="subtitle2" gutterBottom>
            {message.title || 'Notification Title'}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {message.body || 'Notification message will appear here'}
          </Typography>
        </Paper>
      </Box>

      <Divider sx={{ my: 2 }} />

      {/* Android Preview */}
      <Box>
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
          <PhoneAndroidIcon sx={{ mr: 1 }} />
          <Typography variant="subtitle1">Android Preview</Typography>
        </Box>
        <Paper 
          sx={{ 
            p: 2, 
            bgcolor: '#f5f5f5',
            borderRadius: 1,
            maxWidth: '300px'
          }}
        >
          <Typography variant="subtitle2" gutterBottom>
            {message.title || 'Notification Title'}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {message.body || 'Notification message will appear here'}
          </Typography>
          {message.action && (
            <Typography 
              variant="button" 
              sx={{ 
                display: 'block',
                mt: 1,
                color: 'primary.main'
              }}
            >
              {message.action}
            </Typography>
          )}
        </Paper>
      </Box>
    </Box>
  );
};

export default NotificationPreview; 
import React, { useState } from 'react';
import { AppBar, Toolbar, Typography, IconButton, Menu, MenuItem, Box } from '@mui/material';
import { Logout, Menu as MenuIcon, Dashboard, Event, People, Settings, Campaign } from '@mui/icons-material';
import { signOut } from 'firebase/auth';
import { auth } from '../../firebase';
import { useNavigate, Link } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const Header = () => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = async () => {
    try {
      await signOut(auth);
      navigate('/login');
    } catch (error) {
      console.error('Error logging out:', error);
    }
  };

  return (
    <AppBar position="static">
      <Toolbar>
        <Typography variant="h6" style={{ flexGrow: 1 }}>
          Admin Portal
        </Typography>
        {isMobile ? (
          <>
            <IconButton color="inherit" onClick={handleMenuOpen}>
              <MenuIcon />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
            >
              <MenuItem component={Link} to="/dashboard" onClick={handleMenuClose}>
                <Dashboard fontSize="small" style={{ marginRight: '10px' }} />
                Dashboard
              </MenuItem>
              <MenuItem component={Link} to="/events" onClick={handleMenuClose}>
                <Event fontSize="small" style={{ marginRight: '10px' }} />
                Events
              </MenuItem>
              <MenuItem component={Link} to="/users" onClick={handleMenuClose}>
                <People fontSize="small" style={{ marginRight: '10px' }} />
                Users
              </MenuItem>
              <MenuItem component={Link} to="/marketing" onClick={handleMenuClose}>
                <Campaign fontSize="small" style={{ marginRight: '10px' }} />
                Marketing
              </MenuItem>
              <MenuItem component={Link} to="/settings" onClick={handleMenuClose}>
                <Settings fontSize="small" style={{ marginRight: '10px' }} />
                Settings
              </MenuItem>
            </Menu>
          </>
        ) : (
          <Box display="flex" justifyContent="center" alignItems="center" flexGrow={1}>
            <Typography variant="button" component={Link} to="/dashboard" style={{ margin: '0 10px', textDecoration: 'none', color: 'inherit' }}>
              <Dashboard fontSize="small" style={{ marginRight: '5px' }} />
              Dashboard
            </Typography>
            <Typography variant="button" component={Link} to="/events" style={{ margin: '0 10px', textDecoration: 'none', color: 'inherit' }}>
              <Event fontSize="small" style={{ marginRight: '5px' }} />
              Events
            </Typography>
            <Typography variant="button" component={Link} to="/users" style={{ margin: '0 10px', textDecoration: 'none', color: 'inherit' }}>
              <People fontSize="small" style={{ marginRight: '5px' }} />
              Users
            </Typography>
            <Typography variant="button" component={Link} to="/marketing" style={{ margin: '0 10px', textDecoration: 'none', color: 'inherit' }}>
              <Campaign fontSize="small" style={{ marginRight: '5px' }} />
              Marketing
            </Typography>
            <Typography variant="button" component={Link} to="/settings" style={{ margin: '0 10px', textDecoration: 'none', color: 'inherit' }}>
              <Settings fontSize="small" style={{ marginRight: '5px' }} />
              Settings
            </Typography>
          </Box>
        )}
        <IconButton color="inherit" onClick={handleLogout}>
          <Logout />
        </IconButton>
      </Toolbar>
    </AppBar>
  );
};

export default Header;

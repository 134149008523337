import React from 'react';
import { 
  Box, 
  List, 
  ListItem, 
  ListItemText, 
  ListItemAvatar, 
  Avatar,
  Typography,
  Chip,
  IconButton,
  Tooltip
} from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import BlockIcon from '@mui/icons-material/Block';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import moment from 'moment-timezone';

const AttendeesList = ({ attendees }) => {
  const getAttendeeStatus = (attendee) => {
    if (attendee.checkedIn) return 'Checked In';
    if (attendee.ticketType === 'RSVP') return 'RSVP';
    return 'Registered';
  };

  return (
    <Box>
      <Box sx={{ mb: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography variant="h6">
          Attendees ({attendees.length})
        </Typography>
      </Box>

      <List>
        {attendees.map((attendee) => (
          <ListItem
            key={attendee.id}
            secondaryAction={
              <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                <Chip 
                  label={getAttendeeStatus(attendee)}
                  color={attendee.checkedIn ? "success" : "default"}
                  size="small"
                />
                {attendee.checkedIn ? (
                  <Tooltip title="Checked In">
                    <CheckCircleIcon color="success" />
                  </Tooltip>
                ) : (
                  <Tooltip title="Not Checked In">
                    <BlockIcon color="disabled" />
                  </Tooltip>
                )}
              </Box>
            }
          >
            <ListItemAvatar>
              <Avatar>
                <PersonIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={attendee.name}
              secondary={
                <>
                  {attendee.email}
                  <br />
                  Registered: {moment(attendee.registeredAt.toDate()).format('MMM D, YYYY')}
                </>
              }
            />
          </ListItem>
        ))}
      </List>
    </Box>
  );
};

export default AttendeesList; 
import React, { useState } from 'react';
import {
  Box,
  Paper,
  Typography,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  Chip,
  Switch,
  FormControlLabel,
  Divider,
} from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers';
import NotificationPreview from './NotificationPreview';

const CampaignBuilder = ({ onSave }) => {
  const [campaign, setCampaign] = useState({
    name: '',
    segments: [],
    message: {
      title: '',
      body: '',
      action: '',
      actionUrl: '',
    },
    schedule: {
      sendAt: null,
      isRecurring: false,
      frequency: 'once', // once, daily, weekly, monthly
    },
    settings: {
      maxPerUser: 1,
      timeZoneAware: true,
    }
  });

  const userSegments = [
    { id: 'prospective', name: 'Prospective Users' },
    { id: 'new', name: 'New Members' },
    { id: 'engaged', name: 'Event-Engaged Users' },
    { id: 'inactive', name: 'Inactive Members' },
  ];

  const handleSegmentChange = (event) => {
    setCampaign({
      ...campaign,
      segments: event.target.value
    });
  };

  const handleMessageChange = (field) => (event) => {
    setCampaign({
      ...campaign,
      message: {
        ...campaign.message,
        [field]: event.target.value
      }
    });
  };

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <Box>
        <Grid container spacing={3}>
          {/* Campaign Details */}
          <Grid item xs={12} md={8}>
            <Paper sx={{ p: 3, mb: 3 }}>
              <Typography variant="h6" gutterBottom>Campaign Details</Typography>
              <TextField
                fullWidth
                label="Campaign Name"
                value={campaign.name}
                onChange={(e) => setCampaign({ ...campaign, name: e.target.value })}
                sx={{ mb: 2 }}
              />
              
              <FormControl fullWidth sx={{ mb: 2 }}>
                <InputLabel>Target Segments</InputLabel>
                <Select
                  multiple
                  value={campaign.segments}
                  onChange={handleSegmentChange}
                  renderValue={(selected) => (
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                      {selected.map((value) => (
                        <Chip 
                          key={value} 
                          label={userSegments.find(seg => seg.id === value)?.name} 
                        />
                      ))}
                    </Box>
                  )}
                >
                  {userSegments.map((segment) => (
                    <MenuItem key={segment.id} value={segment.id}>
                      {segment.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Paper>

            {/* Message Content */}
            <Paper sx={{ p: 3, mb: 3 }}>
              <Typography variant="h6" gutterBottom>Message Content</Typography>
              <TextField
                fullWidth
                label="Notification Title"
                value={campaign.message.title}
                onChange={handleMessageChange('title')}
                sx={{ mb: 2 }}
              />
              <TextField
                fullWidth
                multiline
                rows={4}
                label="Message Body"
                value={campaign.message.body}
                onChange={handleMessageChange('body')}
                sx={{ mb: 2 }}
              />
              <TextField
                fullWidth
                label="Call to Action"
                value={campaign.message.action}
                onChange={handleMessageChange('action')}
                sx={{ mb: 2 }}
              />
              <TextField
                fullWidth
                label="Action URL"
                value={campaign.message.actionUrl}
                onChange={handleMessageChange('actionUrl')}
              />
            </Paper>

            {/* Schedule Settings */}
            <Paper sx={{ p: 3 }}>
              <Typography variant="h6" gutterBottom>Schedule</Typography>
              <DateTimePicker
                label="Send At"
                value={campaign.schedule.sendAt}
                onChange={(newValue) => setCampaign({
                  ...campaign,
                  schedule: { ...campaign.schedule, sendAt: newValue }
                })}
                renderInput={(params) => <TextField {...params} fullWidth sx={{ mb: 2 }} />}
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={campaign.schedule.isRecurring}
                    onChange={(e) => setCampaign({
                      ...campaign,
                      schedule: { ...campaign.schedule, isRecurring: e.target.checked }
                    })}
                  />
                }
                label="Recurring Campaign"
              />
            </Paper>
          </Grid>

          {/* Preview Section */}
          <Grid item xs={12} md={4}>
            <Paper sx={{ p: 3, position: 'sticky', top: 20 }}>
              <Typography variant="h6" gutterBottom>Preview</Typography>
              <NotificationPreview message={campaign.message} />
            </Paper>
          </Grid>
        </Grid>

        <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
          <Button 
            variant="contained" 
            onClick={() => onSave(campaign)}
          >
            Create Campaign
          </Button>
        </Box>
      </Box>
    </LocalizationProvider>
  );
};

export default CampaignBuilder; 
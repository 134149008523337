import React from 'react';
import {
  Box,
  Paper,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Chip,
  Avatar,
} from '@mui/material';
import moment from 'moment-timezone';

const RecentOrders = ({ attendees = [] }) => {
  // Sort attendees by purchase date, most recent first
  const recentOrders = [...attendees]
    .sort((a, b) => (b.purchaseDate?.toDate?.() || 0) - (a.purchaseDate?.toDate?.() || 0))
    .slice(0, 5);

  const getStatusColor = (status) => {
    switch (status?.toLowerCase()) {
      case 'completed':
        return 'success';
      case 'pending':
        return 'warning';
      case 'cancelled':
        return 'error';
      default:
        return 'default';
    }
  };

  return (
    <Paper sx={{ p: 2, height: '100%' }}>
      <Typography variant="h6" gutterBottom>
        Recent Orders
      </Typography>
      
      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Attendee</TableCell>
              <TableCell>Ticket Type</TableCell>
              <TableCell align="right">Amount</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Date</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {recentOrders.length === 0 ? (
              <TableRow>
                <TableCell colSpan={5} align="center">
                  No orders yet
                </TableCell>
              </TableRow>
            ) : (
              recentOrders.map((order) => (
                <TableRow key={order.id}>
                  <TableCell>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <Avatar sx={{ width: 24, height: 24, mr: 1 }}>
                        {order.name?.[0] || 'A'}
                      </Avatar>
                      {order.name || 'Anonymous'}
                    </Box>
                  </TableCell>
                  <TableCell>{order.ticketType || 'Standard'}</TableCell>
                  <TableCell align="right">
                    ${order.amount?.toFixed(2) || '0.00'}
                  </TableCell>
                  <TableCell>
                    <Chip
                      size="small"
                      label={order.status || 'Pending'}
                      color={getStatusColor(order.status)}
                    />
                  </TableCell>
                  <TableCell>
                    {order.purchaseDate?.toDate
                      ? moment(order.purchaseDate.toDate()).format('MMM D, YYYY')
                      : 'N/A'}
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default RecentOrders; 
import React, { useState, useEffect } from 'react';
import { 
  Box, 
  Grid, 
  Paper, 
  Typography 
} from '@mui/material';
import { 
  PeopleOutline, 
  EventAvailable, 
  ConfirmationNumber,
  TrendingUp 
} from '@mui/icons-material';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { db } from '../../firebase';
import moment from 'moment-timezone';

const Stats = () => {
  const [stats, setStats] = useState({
    totalUsers: 0,
    activeEvents: 0,
    totalTickets: 0,
    monthlyGrowth: 0
  });

  useEffect(() => {
    const fetchStats = async () => {
      try {
        // Get total users
        const usersSnapshot = await getDocs(collection(db, 'users'));
        const totalUsers = usersSnapshot.size;

        // Get active events
        const now = moment().toDate();
        const eventsQuery = query(
          collection(db, 'events'),
          where('date.endDate', '>', now)
        );
        const eventsSnapshot = await getDocs(eventsQuery);
        const activeEvents = eventsSnapshot.size;

        // Calculate total tickets and monthly growth
        let totalTickets = 0;
        let monthlyGrowth = 0;

        setStats({
          totalUsers,
          activeEvents,
          totalTickets,
          monthlyGrowth
        });
      } catch (error) {
        console.error('Error fetching stats:', error);
      }
    };

    fetchStats();
  }, []);

  const statItems = [
    {
      title: 'Total Users',
      value: stats.totalUsers,
      icon: PeopleOutline,
      color: '#1976d2'
    },
    {
      title: 'Active Events',
      value: stats.activeEvents,
      icon: EventAvailable,
      color: '#2e7d32'
    },
    {
      title: 'Total Tickets',
      value: stats.totalTickets,
      icon: ConfirmationNumber,
      color: '#ed6c02'
    },
    {
      title: 'Monthly Growth',
      value: `${stats.monthlyGrowth}%`,
      icon: TrendingUp,
      color: '#9c27b0'
    }
  ];

  return (
    <Grid container spacing={3}>
      {statItems.map((item, index) => (
        <Grid item xs={12} sm={6} md={3} key={index}>
          <Paper
            sx={{
              p: 2,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              height: '100%'
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                mb: 2
              }}
            >
              <item.icon
                sx={{
                  fontSize: 40,
                  color: item.color,
                  mr: 2
                }}
              />
              <Typography variant="h6" component="h2">
                {item.title}
              </Typography>
            </Box>
            <Typography variant="h5" component="h3">
              {item.value}
            </Typography>
          </Paper>
        </Grid>
      ))}
    </Grid>
  );
};

export default Stats;

import React, { useState, useEffect } from 'react';
import { 
  Container, 
  Typography, 
  Grid, 
  Button, 
  Box,
  Tabs,
  Tab,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Chip,
  Avatar,
  Card,
  CardContent,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormGroup,
  FormControlLabel,
  Switch
} from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { doc, getDoc, collection, query, where, getDocs, deleteDoc, orderBy, updateDoc, serverTimestamp, addDoc } from 'firebase/firestore';
import { db } from '../firebase';
import moment from 'moment-timezone';
import {
  Event,
  Message,
  AccessTime,
  Delete,
  Edit,
  ArrowBack,
  Lock,
  LockOpen,
  RestartAlt,
  PersonAdd,
  Warning,
  Star
} from '@mui/icons-material';
import { auth } from '../firebase';

const UserPage = () => {
  const { userId } = useParams();
  const navigate = useNavigate();
  const [user, setUser] = useState(null);
  const [userEvents, setUserEvents] = useState([]);
  const [userMessages, setUserMessages] = useState([]);
  const [loginHistory, setLoginHistory] = useState([]);
  const [currentTab, setCurrentTab] = useState(0);
  const [openDialog, setOpenDialog] = useState(false);
  const [activities, setActivities] = useState([]);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const currentUser = auth.currentUser;
        if (!currentUser) {
          console.error('No authenticated user');
          navigate('/login');
          return;
        }

        // Get fresh token to ensure we have latest claims
        await currentUser.getIdToken(true);
        const tokenResult = await currentUser.getIdTokenResult();
        
        if (!tokenResult.claims.admin) {
          console.error('User is not an admin');
          navigate('/login');
          return;
        }

        // Fetch user profile
        const userDoc = await getDoc(doc(db, 'users', userId));
        if (userDoc.exists()) {
          setUser({
            id: userDoc.id,
            ...userDoc.data(),
            createdAtFormatted: moment(userDoc.data().createdAt).format('MMMM D, YYYY'),
            lastLoginFormatted: moment(userDoc.data().lastLoginTime).fromNow()
          });
        }

        // Fetch user's events
        const eventsQuery = query(
          collection(db, 'eventAttendees'),
          where('userId', '==', userId)
        );
        const eventsSnapshot = await getDocs(eventsQuery);
        const eventsData = await Promise.all(
          eventsSnapshot.docs.map(async (doc) => {
            const eventRef = doc.data().eventRef;
            const eventDoc = eventRef ? await getDoc(eventRef) : null;
            return {
              id: doc.id,
              ...doc.data(),
              eventName: eventDoc?.data()?.name || 'Unknown Event',
              timestamp: moment(doc.data().timestamp).format('MMMM D, YYYY')
            };
          })
        );
        setUserEvents(eventsData);

        // Fetch user's messages from subcollection
        const messagesQuery = query(
          collection(db, `users/${userId}/messages`),
          orderBy('timestamp', 'desc')
        );

        const messagesSnapshot = await getDocs(messagesQuery);
        const messagesData = messagesSnapshot.docs.map(doc => {
          const data = doc.data();
          return {
            id: doc.id,
            ...data,
            timestamp: data.timestamp ? moment(data.timestamp.toDate()).format('MMMM D, YYYY h:mm A') : 'No date'
          };
        });

        setUserMessages(messagesData);

        // Fetch login history
        const loginQuery = query(
          collection(db, 'loginHistory'),
          where('userId', '==', userId)
        );
        const loginSnapshot = await getDocs(loginQuery);
        const loginData = loginSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
          timestamp: moment(doc.data().timestamp).format('MMMM D, YYYY h:mm A')
        }));
        setLoginHistory(loginData);

        // Fetch user activities
        const activitiesQuery = query(
          collection(db, `users/${userId}/activities`),
          orderBy('timestamp', 'desc')
        );
        
        const activitiesSnapshot = await getDocs(activitiesQuery);
        const activitiesData = activitiesSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
          timestamp: doc.data().timestamp ? moment(doc.data().timestamp.toDate()).format('MMMM D, YYYY h:mm A') : 'No date'
        }));
        
        setActivities(activitiesData);

      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };

    fetchUserData();
  }, [userId, navigate]);

  const handleDelete = async () => {
    try {
      await deleteDoc(doc(db, 'users', userId));
      setOpenDialog(false);
      navigate('/users');
    } catch (error) {
      console.error('Error deleting user:', error);
    }
  };

  const handleBanUser = async (banned) => {
    try {
      await updateDoc(doc(db, 'users', userId), { banned });
      await logUserActivity('ban', { banned });
      setUser({ ...user, banned });
    } catch (error) {
      console.error('Error banning user:', error);
    }
  };

  const handleResetPassword = async () => {
    try {
      // Implement password reset logic here
    } catch (error) {
      console.error('Error resetting password:', error);
    }
  };

  const handleToggleComments = async () => {
    try {
      await updateDoc(doc(db, 'users', userId), { canComment: !user.canComment });
      setUser({ ...user, canComment: !user.canComment });
    } catch (error) {
      console.error('Error toggling comments:', error);
    }
  };

  const handleToggleBooking = async () => {
    try {
      await updateDoc(doc(db, 'users', userId), { canBook: !user.canBook });
      setUser({ ...user, canBook: !user.canBook });
    } catch (error) {
      console.error('Error toggling booking:', error);
    }
  };

  const handleToggleMessaging = async () => {
    try {
      await updateDoc(doc(db, 'users', userId), { 
        canMessage: !user.canMessage,
        updatedAt: serverTimestamp()
      });
      await logUserActivity('restriction', {
        type: 'messaging',
        enabled: !user.canMessage
      });
      setUser({ ...user, canMessage: !user.canMessage });
    } catch (error) {
      console.error('Error toggling messaging:', error);
    }
  };

  if (!user) {
    return <Typography>Loading...</Typography>;
  }

  const getUserStatusChip = (user) => {
    if (user.isAdmin) {
      return <Chip label="Admin" color="error" />;
    }
    if (user.profileComplete) {
      return <Chip label="Member" color="success" />;
    }
    return <Chip label="User" color="warning" />;
  };

  const renderUserHeader = () => (
    <Box sx={{ mb: 4 }}>
      <Button
        startIcon={<ArrowBack />}
        onClick={() => navigate('/users')}
        sx={{ mb: 2 }}
      >
        Back to Users
      </Button>
      
      <Grid container spacing={3}>
        <Grid item xs={12} md={4}>
          <Box display="flex" alignItems="center">
            <Avatar 
              sx={{ width: 80, height: 80, mr: 2 }}
              src={user.photoURL}
            >
              {user.firstName?.[0]}
            </Avatar>
            <Box>
              <Typography variant="h4">
                {user.firstName} {user.lastName}
              </Typography>
              <Typography color="textSecondary">{user.email}</Typography>
              <Box sx={{ mt: 1, display: 'flex', gap: 1 }}>
                {getUserStatusChip(user)}
                {user.banned && <Chip label="Banned" color="error" />}
              </Box>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} md={8}>
          <Grid container spacing={2}>
            <Grid item xs={6} md={3}>
              <Typography variant="subtitle2" color="textSecondary">Member Since</Typography>
              <Typography>{user.createdAtFormatted}</Typography>
            </Grid>
            <Grid item xs={6} md={3}>
              <Typography variant="subtitle2" color="textSecondary">Last Active</Typography>
              <Typography>{user.lastLoginFormatted}</Typography>
            </Grid>
            <Grid item xs={6} md={3}>
              <Typography variant="subtitle2" color="textSecondary">Phone</Typography>
              <Typography>{user.phone || 'Not provided'}</Typography>
            </Grid>
            <Grid item xs={6} md={3}>
              <Typography variant="subtitle2" color="textSecondary">Status</Typography>
              <Typography>{user.status || 'Active'}</Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );

  const renderActions = () => (
    <Grid container spacing={3}>
      <Grid item xs={12} md={6}>
        <Card>
          <CardContent>
            <Typography variant="h6" gutterBottom>Account Actions</Typography>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
              <Button
                variant="contained"
                color={user.banned ? 'success' : 'error'}
                startIcon={user.banned ? <LockOpen /> : <Lock />}
                onClick={() => handleBanUser(!user.banned)}
              >
                {user.banned ? 'Unban User' : 'Ban User'}
              </Button>
              <Button
                variant="contained"
                color="warning"
                startIcon={<RestartAlt />}
                onClick={handleResetPassword}
              >
                Reset Password
              </Button>
              <Button
                variant="contained"
                color="error"
                startIcon={<Delete />}
                onClick={() => setOpenDialog(true)}
              >
                Delete Account
              </Button>
            </Box>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} md={6}>
        <Card>
          <CardContent>
            <Typography variant="h6" gutterBottom>Restrictions</Typography>
            <FormGroup>
              <FormControlLabel
                control={<Switch checked={user.canComment} onChange={handleToggleComments} />}
                label="Can Comment"
              />
              <FormControlLabel
                control={<Switch checked={user.canBook} onChange={handleToggleBooking} />}
                label="Can Book Events"
              />
              <FormControlLabel
                control={<Switch checked={user.canMessage} onChange={handleToggleMessaging} />}
                label="Can Send Messages"
              />
            </FormGroup>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );

  const renderEvents = () => (
    <List>
      {userEvents.length === 0 ? (
        <ListItem>
          <ListItemText primary="No events found" />
        </ListItem>
      ) : (
        userEvents.map((event) => (
          <ListItem key={event.id}>
            <ListItemIcon>
              <Event />
            </ListItemIcon>
            <ListItemText
              primary={event.eventName}
              secondary={event.timestamp}
            />
          </ListItem>
        ))
      )}
    </List>
  );

  const renderMessages = () => (
    <List>
      {userMessages.length === 0 ? (
        <ListItem>
          <ListItemText primary="No messages found" />
        </ListItem>
      ) : (
        userMessages.map((message) => (
          <ListItem key={message.id}>
            <ListItemIcon>
              <Message />
            </ListItemIcon>
            <ListItemText 
              primary={
                <Box>
                  <Typography variant="body1">
                    {message.message || message.content || 'No content'}
                  </Typography>
                  {message.type && (
                    <Chip 
                      size="small" 
                      label={message.type}
                      color={message.type === 'system' ? 'primary' : 'default'}
                      sx={{ ml: 1 }}
                    />
                  )}
                </Box>
              }
              secondary={
                <Typography variant="caption" color="text.secondary">
                  {message.timestamp}
                </Typography>
              }
            />
          </ListItem>
        ))
      )}
    </List>
  );

  const fetchUserActivities = async (userId) => {
    try {
      const activitiesQuery = query(
        collection(db, `users/${userId}/activities`),
        orderBy('timestamp', 'desc')
      );
      
      const snapshot = await getDocs(activitiesQuery);
      return snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
        timestamp: doc.data().timestamp ? moment(doc.data().timestamp.toDate()).format('MMMM D, YYYY h:mm A') : 'No date'
      }));
    } catch (error) {
      console.error('Error fetching user activities:', error);
      return [];
    }
  };

  const renderActivity = () => (
    <List>
      {activities.length === 0 ? (
        <ListItem>
          <ListItemText primary="No activity found" />
        </ListItem>
      ) : (
        activities.map((activity) => (
          <ListItem key={activity.id}>
            <ListItemIcon>
              {getActivityIcon(activity.type)}
            </ListItemIcon>
            <ListItemText
              primary={getActivityTitle(activity)}
              secondary={
                <Box>
                  <Typography variant="caption" color="text.secondary">
                    {activity.timestamp}
                  </Typography>
                  {activity.details && (
                    <Typography variant="body2">
                      {activity.details}
                    </Typography>
                  )}
                </Box>
              }
            />
          </ListItem>
        ))
      )}
    </List>
  );

  const getActivityIcon = (type) => {
    switch (type) {
      case 'registration':
        return <PersonAdd color="primary" />;
      case 'message':
        return <Message color="action" />;
      case 'strike':
        return <Warning color="error" />;
      case 'ban':
        return <Lock color="error" />;
      case 'member':
        return <Star color="success" />;
      case 'event':
        return <Event color="primary" />;
      default:
        return <AccessTime />;
    }
  };

  const getActivityTitle = (activity) => {
    switch (activity.type) {
      case 'registration':
        return 'User Registration';
      case 'message':
        return 'New Message';
      case 'strike':
        return `Strike Added (${activity.strikeCount})`;
      case 'ban':
        return activity.banned ? 'User Banned' : 'User Unbanned';
      case 'member':
        return 'Became a Member';
      case 'event':
        return `RSVP to ${activity.eventName}`;
      default:
        return 'Activity';
    }
  };

  const logUserActivity = async (type, details = null) => {
    try {
      const activityRef = collection(db, `users/${userId}/activities`);
      await addDoc(activityRef, {
        type,
        details,
        timestamp: serverTimestamp()
      });
    } catch (error) {
      console.error('Error logging activity:', error);
    }
  };

  return (
    <Container>
      {renderUserHeader()}
      
      <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 3 }}>
        <Tabs value={currentTab} onChange={(e, newValue) => setCurrentTab(newValue)}>
          <Tab label="Activity" />
          <Tab label="Events" />
          <Tab label="Messages" />
          <Tab label="Actions" />
        </Tabs>
      </Box>

      <Box sx={{ mt: 3 }}>
        {currentTab === 0 && renderActivity()}
        {currentTab === 1 && renderEvents()}
        {currentTab === 2 && renderMessages()}
        {currentTab === 3 && renderActions()}
      </Box>

      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogTitle>Delete User</DialogTitle>
        <DialogContent>
          Are you sure you want to delete this user? This action cannot be undone.
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)}>Cancel</Button>
          <Button onClick={handleDelete} color="error">Delete</Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default UserPage; 
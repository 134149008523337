import React, { useState } from 'react';
import {
  Box,
  Paper,
  Typography,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Switch,
  FormControlLabel,
  Grid,
  Chip,
} from '@mui/material';
import {
  Add as AddIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
} from '@mui/icons-material';
import { doc, updateDoc, arrayUnion, arrayRemove } from 'firebase/firestore';
import { db } from '../../firebase';

const TicketManagement = ({ event, attendees = [] }) => {
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedTicket, setSelectedTicket] = useState(null);
  const [ticketForm, setTicketForm] = useState({
    name: '',
    price: 0,
    quantity: 0,
    description: '',
    isActive: true,
  });

  const handleOpenDialog = (ticket = null) => {
    if (ticket) {
      setTicketForm(ticket);
      setSelectedTicket(ticket);
    } else {
      setTicketForm({
        name: '',
        price: 0,
        quantity: 0,
        description: '',
        isActive: true,
      });
      setSelectedTicket(null);
    }
    setOpenDialog(true);
  };

  const handleSaveTicket = async () => {
    try {
      const eventRef = doc(db, 'events', event.id);
      if (selectedTicket) {
        // Update existing ticket
        const updatedTickets = event.tickets.map(t => 
          t.id === selectedTicket.id ? { ...ticketForm, id: t.id } : t
        );
        await updateDoc(eventRef, { tickets: updatedTickets });
      } else {
        // Add new ticket
        const newTicket = {
          ...ticketForm,
          id: Date.now().toString(),
          createdAt: new Date(),
        };
        await updateDoc(eventRef, {
          tickets: arrayUnion(newTicket),
        });
      }
      setOpenDialog(false);
    } catch (error) {
      console.error('Error saving ticket:', error);
    }
  };

  const handleDeleteTicket = async (ticket) => {
    if (window.confirm('Are you sure you want to delete this ticket type?')) {
      try {
        const eventRef = doc(db, 'events', event.id);
        await updateDoc(eventRef, {
          tickets: arrayRemove(ticket),
        });
      } catch (error) {
        console.error('Error deleting ticket:', error);
      }
    }
  };

  const getTicketStats = (ticketId) => {
    const ticketAttendees = attendees.filter(a => a.ticketId === ticketId);
    return {
      sold: ticketAttendees.length,
      revenue: ticketAttendees.reduce((sum, a) => sum + (a.amount || 0), 0),
    };
  };

  return (
    <Box>
      <Box sx={{ mb: 3, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography variant="h6">Ticket Types</Typography>
        <Button
          variant="contained"
          startIcon={<AddIcon />}
          onClick={() => handleOpenDialog()}
        >
          Add Ticket Type
        </Button>
      </Box>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Price</TableCell>
              <TableCell>Quantity</TableCell>
              <TableCell>Sold</TableCell>
              <TableCell>Revenue</TableCell>
              <TableCell>Status</TableCell>
              <TableCell align="right">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {event.tickets?.map((ticket) => {
              const stats = getTicketStats(ticket.id);
              return (
                <TableRow key={ticket.id}>
                  <TableCell>{ticket.name}</TableCell>
                  <TableCell>${ticket.price.toFixed(2)}</TableCell>
                  <TableCell>{ticket.quantity}</TableCell>
                  <TableCell>{stats.sold}</TableCell>
                  <TableCell>${stats.revenue.toFixed(2)}</TableCell>
                  <TableCell>
                    <Chip
                      size="small"
                      label={ticket.isActive ? 'Active' : 'Inactive'}
                      color={ticket.isActive ? 'success' : 'default'}
                    />
                  </TableCell>
                  <TableCell align="right">
                    <IconButton onClick={() => handleOpenDialog(ticket)}>
                      <EditIcon />
                    </IconButton>
                    <IconButton onClick={() => handleDeleteTicket(ticket)}>
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog open={openDialog} onClose={() => setOpenDialog(false)} maxWidth="sm" fullWidth>
        <DialogTitle>
          {selectedTicket ? 'Edit Ticket Type' : 'Add Ticket Type'}
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2} sx={{ mt: 1 }}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Ticket Name"
                value={ticketForm.name}
                onChange={(e) => setTicketForm({ ...ticketForm, name: e.target.value })}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                type="number"
                label="Price"
                value={ticketForm.price}
                onChange={(e) => setTicketForm({ ...ticketForm, price: parseFloat(e.target.value) })}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                type="number"
                label="Quantity"
                value={ticketForm.quantity}
                onChange={(e) => setTicketForm({ ...ticketForm, quantity: parseInt(e.target.value) })}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                multiline
                rows={3}
                label="Description"
                value={ticketForm.description}
                onChange={(e) => setTicketForm({ ...ticketForm, description: e.target.value })}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Switch
                    checked={ticketForm.isActive}
                    onChange={(e) => setTicketForm({ ...ticketForm, isActive: e.target.checked })}
                  />
                }
                label="Active"
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)}>Cancel</Button>
          <Button onClick={handleSaveTicket} variant="contained">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default TicketManagement; 
import React, { useState, useEffect } from 'react';
import { 
  Container, 
  Box, 
  Button, 
  Typography,
  Grid,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  InputAdornment,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { collection, query, orderBy, getDocs } from 'firebase/firestore';
import { db } from '../firebase';
import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';
import EventCard from '../components/events/EventCard';
import moment from 'moment-timezone';

const Events = () => {
  const [events, setEvents] = useState([]);
  const [filteredEvents, setFilteredEvents] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [sortBy, setSortBy] = useState('date');
  const navigate = useNavigate();

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const q = query(
          collection(db, 'events'), 
          orderBy('date.start', 'desc')
        );
        const querySnapshot = await getDocs(q);
        const eventList = querySnapshot.docs.map(doc => {
          const data = doc.data();
          return {
            id: doc.id,
            ...data,
            name: data.title,
            formattedDate: moment(data.date.start.toDate()).format('MMM D, YYYY'),
            status: getEventStatus({
              startDate: data.date.start.toDate(),
              endDate: data.date.end.toDate()
            })
          };
        });
        setEvents(eventList);
        setFilteredEvents(eventList);
      } catch (error) {
        console.error('Error fetching events:', error);
      }
    };
    fetchEvents();
  }, []);

  const getEventStatus = (date) => {
    if (!date?.startDate || !date?.endDate) return 'N/A';
    const now = moment();
    const start = moment(date.startDate);
    const end = moment(date.endDate);

    if (now.isBefore(start)) return 'Upcoming';
    if (now.isAfter(end)) return 'Ended';
    return 'In Progress';
  };

  useEffect(() => {
    const filtered = events.filter(event => 
      event.name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      event.venue?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      event.city?.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const sorted = [...filtered].sort((a, b) => {
      switch (sortBy) {
        case 'name':
          return a.name?.localeCompare(b.name);
        case 'venue':
          return a.venue?.localeCompare(b.venue);
        case 'status':
          return a.status?.localeCompare(b.status);
        case 'date':
        default:
          return moment(b.startDate).diff(moment(a.startDate));
      }
    });
    setFilteredEvents(sorted);
  }, [searchTerm, sortBy, events]);

  return (
    <Container maxWidth="xl">
      <Box sx={{ 
        display: 'flex', 
        justifyContent: 'space-between', 
        alignItems: 'center',
        mb: 4 
      }}>
        <Typography variant="h4" component="h1">
          Events
        </Typography>
        <Button 
          variant="contained" 
          color="primary" 
          startIcon={<AddIcon />}
          onClick={() => navigate('/events/create')}
        >
          Create Event
        </Button>
      </Box>

      <Box sx={{ mb: 3, display: 'flex', gap: 2 }}>
        <TextField
          placeholder="Search events..."
          variant="outlined"
          fullWidth
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
        <FormControl sx={{ minWidth: 120 }}>
          <InputLabel>Sort By</InputLabel>
          <Select
            value={sortBy}
            label="Sort By"
            onChange={(e) => setSortBy(e.target.value)}
          >
            <MenuItem value="date">Date</MenuItem>
            <MenuItem value="name">Name</MenuItem>
            <MenuItem value="venue">Venue</MenuItem>
            <MenuItem value="status">Status</MenuItem>
          </Select>
        </FormControl>
      </Box>

      <Grid container spacing={3}>
        {filteredEvents.map((event) => (
          <Grid item xs={12} sm={6} md={4} key={event.id}>
            <EventCard 
              event={event}
              onClick={() => navigate(`/events/${event.id}`)}
            />
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default Events;

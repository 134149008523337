import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import DashboardLayout from './layouts/DashboardLayout';
import Login from './pages/Login';
import Dashboard from './pages/Dashboard';
import Events from './pages/Events';
import EventPage from './pages/EventPage';
import Users from './pages/Users';
import UserPage from './components/UserPage';
import Marketing from './pages/MarketingPage';
import ProtectedRoute from './components/common/ProtectedRoute';

const AppRoute = ({ Component }) => {
  if (!Component) {
    console.error('Component is undefined in AppRoute');
    return null;
  }
  
  return (
    <ProtectedRoute>
      <DashboardLayout>
        <Component />
      </DashboardLayout>
    </ProtectedRoute>
  );
};

function App() {
  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="/dashboard" element={<AppRoute Component={Dashboard} />} />
      <Route path="/events" element={<AppRoute Component={Events} />} />
      <Route path="/events/:eventId" element={<EventPage />} />
      <Route path="/users" element={<AppRoute Component={Users} />} />
      <Route path="/users/:userId" element={<AppRoute Component={UserPage} />} />
      <Route path="/marketing" element={<AppRoute Component={Marketing} />} />
      <Route path="/" element={<Navigate to="/dashboard" />} />
    </Routes>
  );
}

export default App; 
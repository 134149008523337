import React from 'react';
import {
  Box,
  TextField,
  Typography,
  Button,
  Grid,
  Paper,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Chip,
  Divider,
  IconButton,
  Tooltip
} from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import GroupIcon from '@mui/icons-material/Group';
import CategoryIcon from '@mui/icons-material/Category';
import LinkIcon from '@mui/icons-material/Link';
import moment from 'moment-timezone';

const EventDetails = ({ event, isEditing, onEdit, onChange, onSave }) => {
  const handleChange = (field) => (e) => {
    onChange({
      ...event,
      [field]: e.target.value
    });
  };

  const handleDateChange = (field) => (newDate) => {
    onChange({
      ...event,
      date: {
        ...event.date,
        [field]: newDate.toDate()
      }
    });
  };

  if (isEditing) {
    return (
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <Box>
          <Paper sx={{ p: 3, mb: 3 }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
              <Typography variant="h5">Edit Event Details</Typography>
              <Button
                variant="contained"
                startIcon={<SaveIcon />}
                onClick={onSave}
                color="primary"
              >
                Save Changes
              </Button>
            </Box>

            <Grid container spacing={3}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Event Title"
                  value={event.title || ''}
                  onChange={handleChange('title')}
                  variant="outlined"
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  fullWidth
                  multiline
                  rows={4}
                  label="Description"
                  value={event.description || ''}
                  onChange={handleChange('description')}
                  variant="outlined"
                  helperText="Markdown formatting supported"
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <DateTimePicker
                  label="Start Date & Time"
                  value={moment(event.date?.start)}
                  onChange={handleDateChange('start')}
                  renderInput={(params) => <TextField {...params} fullWidth />}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <DateTimePicker
                  label="End Date & Time"
                  value={moment(event.date?.end)}
                  onChange={handleDateChange('end')}
                  renderInput={(params) => <TextField {...params} fullWidth />}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="Venue"
                  value={event.venue || ''}
                  onChange={handleChange('venue')}
                  variant="outlined"
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="City"
                  value={event.city || ''}
                  onChange={handleChange('city')}
                  variant="outlined"
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label="Capacity"
                  type="number"
                  value={event.capacity || ''}
                  onChange={handleChange('capacity')}
                  variant="outlined"
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <FormControl fullWidth variant="outlined">
                  <InputLabel>Event Type</InputLabel>
                  <Select
                    value={event.type || 'general'}
                    onChange={handleChange('type')}
                    label="Event Type"
                  >
                    <MenuItem value="general">General</MenuItem>
                    <MenuItem value="workshop">Workshop</MenuItem>
                    <MenuItem value="conference">Conference</MenuItem>
                    <MenuItem value="meetup">Meetup</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Event URL"
                  value={event.eventUrl || ''}
                  onChange={handleChange('eventUrl')}
                  variant="outlined"
                  helperText="Custom URL for your event page"
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Tags"
                  value={event.tags?.join(', ') || ''}
                  onChange={(e) => {
                    const tags = e.target.value.split(',').map(tag => tag.trim());
                    onChange({
                      ...event,
                      tags
                    });
                  }}
                  variant="outlined"
                  helperText="Separate tags with commas"
                />
              </Grid>
            </Grid>
          </Paper>
        </Box>
      </LocalizationProvider>
    );
  }

  return (
    <Box>
      <Paper sx={{ p: 3, mb: 3 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', mb: 3 }}>
          <Box>
            <Typography variant="h4" gutterBottom>{event.title}</Typography>
            <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap' }}>
              {event.tags?.map((tag) => (
                <Chip key={tag} label={tag} size="small" />
              ))}
            </Box>
          </Box>
          <IconButton onClick={onEdit} color="primary">
            <EditIcon />
          </IconButton>
        </Box>

        <Divider sx={{ my: 3 }} />

        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="body1" sx={{ whiteSpace: 'pre-line' }}>
              {event.description}
            </Typography>
          </Grid>
        </Grid>
      </Paper>

      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Paper sx={{ p: 3 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
              <AccessTimeIcon sx={{ mr: 1 }} color="primary" />
              <Typography variant="h6">Date & Time</Typography>
            </Box>
            <Typography variant="body1" gutterBottom>
              {moment(event.date?.start).format('dddd, MMMM D, YYYY')}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {moment(event.date?.start).format('h:mm A')} - 
              {moment(event.date?.end).format('h:mm A')}
            </Typography>
          </Paper>
        </Grid>

        <Grid item xs={12} md={6}>
          <Paper sx={{ p: 3 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
              <LocationOnIcon sx={{ mr: 1 }} color="primary" />
              <Typography variant="h6">Location</Typography>
            </Box>
            <Typography variant="body1" gutterBottom>
              {event.venue}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {event.city}
            </Typography>
          </Paper>
        </Grid>

        <Grid item xs={12} md={6}>
          <Paper sx={{ p: 3 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
              <GroupIcon sx={{ mr: 1 }} color="primary" />
              <Typography variant="h6">Capacity</Typography>
            </Box>
            <Typography variant="body1">
              {event.capacity} attendees
            </Typography>
          </Paper>
        </Grid>

        <Grid item xs={12} md={6}>
          <Paper sx={{ p: 3 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
              <CategoryIcon sx={{ mr: 1 }} color="primary" />
              <Typography variant="h6">Event Type</Typography>
            </Box>
            <Typography variant="body1" sx={{ textTransform: 'capitalize' }}>
              {event.type || 'General'}
            </Typography>
          </Paper>
        </Grid>

        {event.eventUrl && (
          <Grid item xs={12}>
            <Paper sx={{ p: 3 }}>
              <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                <LinkIcon sx={{ mr: 1 }} color="primary" />
                <Typography variant="h6">Event URL</Typography>
              </Box>
              <Typography variant="body1">
                {event.eventUrl}
              </Typography>
            </Paper>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default EventDetails; 
import React from 'react';
import {
  Box,
  Paper,
  Typography,
  Grid,
  LinearProgress,
  Tooltip,
} from '@mui/material';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip as ChartTooltip,
  ResponsiveContainer
} from 'recharts';

const CampaignAnalytics = ({ campaign }) => {
  const metrics = [
    {
      label: 'Delivery Rate',
      value: campaign.metrics?.deliveryRate || 0,
      color: 'primary.main',
    },
    {
      label: 'Open Rate',
      value: campaign.metrics?.openRate || 0,
      color: 'success.main',
    },
    {
      label: 'Click Rate',
      value: campaign.metrics?.clickRate || 0,
      color: 'warning.main',
    },
    {
      label: 'Conversion Rate',
      value: campaign.metrics?.conversionRate || 0,
      color: 'secondary.main',
    }
  ];

  return (
    <Box>
      <Grid container spacing={3}>
        {/* Key Metrics */}
        {metrics.map((metric) => (
          <Grid item xs={12} sm={6} md={3} key={metric.label}>
            <Paper sx={{ p: 2 }}>
              <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                {metric.label}
              </Typography>
              <Typography variant="h4" component="div">
                {metric.value}%
              </Typography>
              <Tooltip title={`${metric.value}% of total`}>
                <LinearProgress 
                  variant="determinate" 
                  value={metric.value} 
                  sx={{ 
                    mt: 1,
                    height: 8,
                    borderRadius: 1,
                    bgcolor: 'grey.100',
                    '& .MuiLinearProgress-bar': {
                      bgcolor: metric.color
                    }
                  }} 
                />
              </Tooltip>
            </Paper>
          </Grid>
        ))}

        {/* Engagement Chart */}
        <Grid item xs={12}>
          <Paper sx={{ p: 3 }}>
            <Typography variant="h6" gutterBottom>
              Engagement Over Time
            </Typography>
            <Box sx={{ height: 300 }}>
              <ResponsiveContainer width="100%" height="100%">
                <BarChart data={campaign.engagementData || []}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="date" />
                  <YAxis />
                  <ChartTooltip />
                  <Bar dataKey="opens" fill="#1976d2" name="Opens" />
                  <Bar dataKey="clicks" fill="#2e7d32" name="Clicks" />
                </BarChart>
              </ResponsiveContainer>
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default CampaignAnalytics; 
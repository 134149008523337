import React from 'react';
import { 
  Card, 
  CardContent, 
  CardMedia, 
  Typography 
} from '@mui/material';
import moment from 'moment-timezone';

const EventCard = ({ event, onClick }) => {
  return (
    <Card 
      sx={{ 
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        cursor: 'pointer',
        '&:hover': { boxShadow: 6 }
      }}
      onClick={onClick}
    >
      {event.image?.url && (
        <CardMedia
          component="img"
          height="140"
          image={event.image.url}
          alt={event.title || 'Event image'}
        />
      )}
      <CardContent sx={{ flexGrow: 1 }}>
        <Typography variant="h6" gutterBottom>
          {event.title}
        </Typography>
        <Typography color="textSecondary">
          {moment(event.date.start.toDate()).format('MMM D, YYYY')}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default EventCard; 
import React, { useState, useEffect } from 'react';
import { 
  Box, 
  List, 
  ListItem, 
  ListItemText, 
  ListItemAvatar, 
  Avatar,
  Typography,
  Divider 
} from '@mui/material';
import { 
  PersonAdd, 
  EventAvailable, 
  ShoppingCart 
} from '@mui/icons-material';
import { collection, query, orderBy, limit, getDocs } from 'firebase/firestore';
import { db } from '../../firebase';
import moment from 'moment-timezone';

const UserActivity = () => {
  const [activities, setActivities] = useState([]);

  useEffect(() => {
    const fetchActivities = async () => {
      try {
        // Fetch recent user registrations
        const registrationsQuery = query(
          collection(db, 'users'),
          orderBy('createdAt', 'desc'),
          limit(5)
        );
        const registrationsSnapshot = await getDocs(registrationsQuery);
        
        // Fetch recent ticket purchases
        const ticketsQuery = query(
          collection(db, 'tickets'),
          orderBy('purchaseDate', 'desc'),
          limit(5)
        );
        const ticketsSnapshot = await getDocs(ticketsQuery);

        // Combine and sort activities
        const allActivities = [
          ...registrationsSnapshot.docs.map(doc => ({
            id: doc.id,
            type: 'registration',
            user: doc.data(),
            timestamp: doc.data().createdAt
          })),
          ...ticketsSnapshot.docs.map(doc => ({
            id: doc.id,
            type: 'ticket',
            data: doc.data(),
            timestamp: doc.data().purchaseDate
          }))
        ].sort((a, b) => b.timestamp - a.timestamp).slice(0, 5);

        setActivities(allActivities);
      } catch (error) {
        console.error('Error fetching activities:', error);
      }
    };

    fetchActivities();
  }, []);

  const getActivityIcon = (type) => {
    switch (type) {
      case 'registration':
        return <PersonAdd color="primary" />;
      case 'ticket':
        return <ShoppingCart color="secondary" />;
      default:
        return <EventAvailable />;
    }
  };

  const formatActivityText = (activity) => {
    switch (activity.type) {
      case 'registration':
        return {
          primary: `${activity.user.firstName} ${activity.user.lastName}`,
          secondary: 'New user registration'
        };
      case 'ticket':
        return {
          primary: `${activity.data.userName}`,
          secondary: `Purchased ticket for ${activity.data.eventName}`
        };
      default:
        return {
          primary: 'Unknown Activity',
          secondary: ''
        };
    }
  };

  return (
    <Box>
      <Typography variant="h6" gutterBottom>Recent Activity</Typography>
      <List>
        {activities.map((activity, index) => (
          <React.Fragment key={activity.id}>
            <ListItem alignItems="flex-start">
              <ListItemAvatar>
                <Avatar>{getActivityIcon(activity.type)}</Avatar>
              </ListItemAvatar>
              <ListItemText
                {...formatActivityText(activity)}
                secondary={
                  <>
                    <Typography component="span" variant="body2" color="text.secondary">
                      {moment(activity.timestamp.toDate()).fromNow()}
                    </Typography>
                  </>
                }
              />
            </ListItem>
            {index < activities.length - 1 && <Divider variant="inset" component="li" />}
          </React.Fragment>
        ))}
      </List>
    </Box>
  );
};

export default UserActivity; 
import React, { useState } from 'react';
import {
  Box,
  Paper,
  Typography,
  FormControl,
  FormControlLabel,
  Switch,
  TextField,
  Button,
  Select,
  MenuItem,
  InputLabel,
  Grid,
  Divider,
  Alert,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
import SecurityIcon from '@mui/icons-material/Security';
import NotificationsIcon from '@mui/icons-material/Notifications';
import LanguageIcon from '@mui/icons-material/Language';

const EventSettings = ({ event, onChange, onSave, onDelete }) => {
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [hasChanges, setHasChanges] = useState(false);

  const handleChange = (field) => (e) => {
    setHasChanges(true);
    onChange({
      ...event,
      [field]: e.target.type === 'checkbox' ? e.target.checked : e.target.value
    });
  };

  const handleSave = () => {
    onSave();
    setHasChanges(false);
  };

  return (
    <Box>
      {hasChanges && (
        <Alert severity="info" sx={{ mb: 2 }}>
          You have unsaved changes
        </Alert>
      )}

      <Grid container spacing={3}>
        {/* Visibility Settings */}
        <Grid item xs={12} md={6}>
          <Paper sx={{ p: 3 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
              <VisibilityIcon sx={{ mr: 1 }} color="primary" />
              <Typography variant="h6">Visibility Settings</Typography>
            </Box>
            <FormControl fullWidth sx={{ mb: 2 }}>
              <InputLabel>Event Visibility</InputLabel>
              <Select
                value={event.visibility || 'public'}
                onChange={handleChange('visibility')}
                label="Event Visibility"
              >
                <MenuItem value="public">Public - Visible to everyone</MenuItem>
                <MenuItem value="private">Private - Invitation only</MenuItem>
                <MenuItem value="unlisted">Unlisted - Hidden from listings</MenuItem>
              </Select>
            </FormControl>
            <FormControlLabel
              control={
                <Switch
                  checked={event.showAttendees || false}
                  onChange={handleChange('showAttendees')}
                />
              }
              label="Show attendee list publicly"
            />
          </Paper>
        </Grid>

        {/* Registration Settings */}
        <Grid item xs={12} md={6}>
          <Paper sx={{ p: 3 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
              <SecurityIcon sx={{ mr: 1 }} color="primary" />
              <Typography variant="h6">Registration Settings</Typography>
            </Box>
            <FormControlLabel
              control={
                <Switch
                  checked={event.requireApproval || false}
                  onChange={handleChange('requireApproval')}
                />
              }
              label="Require approval for registrations"
            />
            <FormControlLabel
              control={
                <Switch
                  checked={event.waitlist || false}
                  onChange={handleChange('waitlist')}
                />
              }
              label="Enable waitlist when full"
            />
            <TextField
              fullWidth
              label="Registration Code"
              value={event.registrationCode || ''}
              onChange={handleChange('registrationCode')}
              helperText="Optional: Require a code to register"
              sx={{ mt: 2 }}
            />
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default EventSettings; 

import React from 'react';
import { Container, Typography, Grid, Paper } from '@mui/material';
import Stats from '../components/dashboard/Stats';
import RecentEvents from '../components/dashboard/RecentEvents';
import UserActivity from '../components/dashboard/UserActivity';

const Dashboard = () => {
  return (
    <Container>
      <Typography variant="h4" component="h1" gutterBottom>
        Analytics Dashboard
      </Typography>
      
      <Stats />
      
      <Grid container spacing={3} sx={{ mt: 3 }}>
        <Grid item xs={12} md={6}>
          <Paper elevation={3} sx={{ p: 3 }}>
            <RecentEvents />
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper elevation={3} sx={{ p: 3 }}>
            <UserActivity />
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Dashboard;

import React from 'react';
import {
  Box,
  Grid,
  Paper,
  Typography,
  Avatar,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Divider
} from '@mui/material';
import {
  Campaign,
  Group,
  TrendingUp,
  NotificationsActive
} from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

const MarketingDashboard = ({ analytics }) => {
  const navigate = useNavigate();
  
  const stats = [
    {
      title: 'Active Campaigns',
      value: analytics?.activeCampaigns || 0,
      icon: <Campaign />,
      color: '#1976d2'
    },
    {
      title: 'Total Subscribers',
      value: analytics?.totalSubscribers || 0,
      icon: <Group />,
      color: '#2e7d32'
    },
    {
      title: 'Avg. Open Rate',
      value: `${analytics?.avgOpenRate || 0}%`,
      icon: <TrendingUp />,
      color: '#ed6c02'
    },
    {
      title: 'Push Notifications',
      value: analytics?.pushNotifications || 0,
      icon: <NotificationsActive />,
      color: '#9c27b0'
    }
  ];

  return (
    <Box>
      <Grid container spacing={3}>
        {/* Stats Cards - Using similar styling to EventAnalytics */}
        {stats.map((stat) => (
          <Grid item xs={12} sm={6} md={3} key={stat.title}>
            <Paper 
              sx={{ 
                p: 2,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                height: '100%',
                cursor: 'pointer'
              }}
              onClick={() => navigate(`/marketing/${stat.title.toLowerCase().replace(' ', '-')}`)}
            >
              <Avatar 
                sx={{ 
                  bgcolor: stat.color,
                  width: 56,
                  height: 56,
                  mb: 1
                }}
              >
                {stat.icon}
              </Avatar>
              <Typography variant="h4" component="div">
                {stat.value}
              </Typography>
              <Typography color="text.secondary" variant="body2">
                {stat.title}
              </Typography>
            </Paper>
          </Grid>
        ))}

        {/* Recent Campaigns */}
        <Grid item xs={12} md={6}>
          <Paper sx={{ p: 2 }}>
            <Typography variant="h6" gutterBottom>
              Recent Campaigns
            </Typography>
            <List>
              {analytics?.recentCampaigns?.map((campaign, index) => (
                <React.Fragment key={campaign.id}>
                  <ListItem alignItems="flex-start">
                    <ListItemAvatar>
                      <Avatar sx={{ bgcolor: campaign.status === 'active' ? 'success.main' : 'grey.500' }}>
                        <Campaign />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={campaign.name}
                      secondary={
                        <>
                          <Typography component="span" variant="body2" color="text.secondary">
                            {`Sent: ${campaign.sentCount} • Opened: ${campaign.openRate}%`}
                          </Typography>
                        </>
                      }
                    />
                  </ListItem>
                  {index < analytics.recentCampaigns.length - 1 && <Divider variant="inset" />}
                </React.Fragment>
              ))}
            </List>
          </Paper>
        </Grid>

        {/* Segment Performance */}
        <Grid item xs={12} md={6}>
          <Paper sx={{ p: 2 }}>
            <Typography variant="h6" gutterBottom>
              Segment Performance
            </Typography>
            <List>
              {analytics?.segmentPerformance?.map((segment, index) => (
                <React.Fragment key={segment.id}>
                  <ListItem>
                    <ListItemText
                      primary={segment.name}
                      secondary={`Subscribers: ${segment.subscribers} • Engagement: ${segment.engagementRate}%`}
                    />
                  </ListItem>
                  {index < analytics.segmentPerformance.length - 1 && <Divider />}
                </React.Fragment>
              ))}
            </List>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default MarketingDashboard; 
import React, { useEffect, useState } from 'react';
import { 
  Card, 
  CardContent, 
  Typography, 
  Button, 
  Box, 
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Paper,
  Tabs,
  Tab,
  Divider,
  Chip
} from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { doc, getDoc, deleteDoc, collection, query, where, getDocs, updateDoc } from 'firebase/firestore';
import { db } from '../firebase';
import moment from 'moment-timezone';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import PeopleIcon from '@mui/icons-material/People';
import SettingsIcon from '@mui/icons-material/Settings';
import MessageIcon from '@mui/icons-material/Message';
import AnalyticsIcon from '@mui/icons-material/Analytics';
import EventHeader from '../components/events/EventHeader';
import EventBanner from '../components/events/EventBanner';
import EventDetails from '../components/events/EventDetails';
import AttendeesList from '../components/events/AttendeesList';
import MessageBoard from '../components/events/MessageBoard';
import EventAnalytics from '../components/events/EventAnalytics';
import DeleteEventDialog from '../components/events/DeleteEventDialog';
import EventSettings from '../components/events/EventSettings';
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import RecentOrders from '../components/events/RecentOrders';
import RecentActivity from '../components/events/RecentActivity';
import TicketManagement from '../components/events/TicketManagement';

const EventPage = () => {
  const [event, setEvent] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [currentTab, setCurrentTab] = useState(0);
  const [attendees, setAttendees] = useState([]);
  const [messages, setMessages] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [hasChanges, setHasChanges] = useState(false);
  const [analytics, setAnalytics] = useState({
    totalAttendees: 0,
    ticketsSold: 0,
    messageCount: 0,
    engagementRate: 0,
    visibility: 'public'
  });
  
  const { eventId } = useParams();
  const navigate = useNavigate();

  // Add handleDelete inside the component
  const handleDelete = async () => {
    try {
      await deleteDoc(doc(db, 'events', eventId));
      navigate('/events');
    } catch (error) {
      console.error('Error deleting event:', error);
    }
  };

  // Fetch event details
  useEffect(() => {
    const fetchEventData = async () => {
      try {
        const eventDoc = await getDoc(doc(db, 'events', eventId));
        if (eventDoc.exists()) {
          const eventData = { id: eventDoc.id, ...eventDoc.data() };
          setEvent(eventData);
          
          // Fetch related data
          await Promise.all([
            fetchAttendees(eventId),
            fetchMessages(eventId),
            fetchAnalytics(eventId)
          ]);
        } else {
          console.error('No such event!');
          navigate('/events');
        }
      } catch (error) {
        console.error('Error fetching event:', error);
      }
    };

    fetchEventData();
  }, [eventId, navigate]);

  // Fetch attendees
  const fetchAttendees = async (eventId) => {
    const q = query(
      collection(db, 'eventAttendees'),
      where('eventId', '==', eventId)
    );
    const snapshot = await getDocs(q);
    setAttendees(snapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    })));
  };

  // Fetch messages (blank-text)
  const fetchMessages = async (eventId) => {
    const q = query(
      collection(db, 'eventMessages'),
      where('eventId', '==', eventId)
    );
    const snapshot = await getDocs(q);
    setMessages(snapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    })));
  };

  // Fetch analytics
  const fetchAnalytics = async (eventId) => {
    // Implement analytics fetching logic
    setAnalytics({
      totalAttendees: attendees.length,
      ticketsSold: attendees.filter(a => a.ticketType !== 'RSVP').length,
      messageCount: messages.length,
      engagementRate: calculateEngagementRate(attendees, messages)
    });
  };

  const calculateEngagementRate = (attendees, messages) => {
    if (attendees.length === 0) return 0;
    const uniqueMessageSenders = new Set(messages.map(m => m.userId)).size;
    return Math.round((uniqueMessageSenders / attendees.length) * 100);
  };

  // Add handleSaveChanges function
  const handleSaveChanges = async () => {
    try {
      await updateDoc(doc(db, 'events', eventId), event);
      setHasChanges(false);
      setIsEditing(false);
    } catch (error) {
      console.error('Error saving changes:', error);
    }
  };

  // Modify renderTabContent
  const renderTabContent = () => {
    switch (currentTab) {
      case 0: // Overview
        return (
          <Box>
            <EventAnalytics 
              analytics={analytics}
              attendees={attendees}
              messages={messages}
            />
            <Grid container spacing={3} sx={{ mt: 3 }}>
              <Grid item xs={12} md={6}>
                <RecentOrders attendees={attendees} />
              </Grid>
              <Grid item xs={12} md={6}>
                <RecentActivity messages={messages} />
              </Grid>
            </Grid>
          </Box>
        );
      case 1: // Event Details
        return (
          <EventDetails 
            event={event}
            isEditing={isEditing}
            onEdit={() => setIsEditing(true)}
            onChange={(updatedEvent) => {
              setEvent(updatedEvent);
              setHasChanges(true);
            }}
            onSave={handleSaveChanges}
          />
        );
      case 2: // Tickets
        return <TicketManagement event={event} attendees={attendees} />;
      case 3: // Attendees
        return <AttendeesList attendees={attendees} />;
      case 4: // Messages
        return (
          <MessageBoard 
            messages={messages} 
            eventId={eventId}
            publicUrl={`https://live.blanklocations.com/events/${eventId}/messages`}
          />
        );
      case 5: // Settings
        return (
          <EventSettings 
            event={event}
            onChange={(updatedEvent) => {
              setEvent(updatedEvent);
              setHasChanges(true);
            }}
            onSave={handleSaveChanges}
            onDelete={() => setOpenDialog(true)}
          />
        );
      default:
        return null;
    }
  };

  if (!event) return null;

  return (
    <Box sx={{ maxWidth: 1200, mx: 'auto', p: 3 }}>
      <Box sx={{ mb: 3 }}>
        <Typography variant="h4" gutterBottom>
          {event.title}
        </Typography>
        <Button
          startIcon={<ArrowBackIcon />}
          onClick={() => navigate('/events')}
        >
          Back to Events
        </Button>
      </Box>

      {/* Main content */}
      <Card>
        <CardContent>
          <Tabs 
            value={currentTab}
            onChange={(e, newValue) => setCurrentTab(newValue)}
            sx={{ mb: 3 }}
          >
            <Tab label="Overview" icon={<AnalyticsIcon />} />
            <Tab label="Event Details" icon={<EditIcon />} />
            <Tab label="Tickets" icon={<ConfirmationNumberIcon />} />
            <Tab label="Attendees" icon={<PeopleIcon />} />
            <Tab label="Messages" icon={<MessageIcon />} />
            <Tab label="Settings" icon={<SettingsIcon />} />
          </Tabs>

          {renderTabContent()}
        </CardContent>
      </Card>

      <DeleteEventDialog 
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        onConfirm={handleDelete}
      />
    </Box>
  );
};

export default EventPage;

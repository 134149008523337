import React from 'react';
import {
  Box,
  Paper,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Divider,
} from '@mui/material';
import { Message as MessageIcon } from '@mui/icons-material';
import moment from 'moment-timezone';

const RecentActivity = ({ messages = [] }) => {
  // Sort messages by date, most recent first
  const recentMessages = [...messages]
    .sort((a, b) => (b.createdAt?.toDate?.() || 0) - (a.createdAt?.toDate?.() || 0))
    .slice(0, 5);

  return (
    <Paper sx={{ p: 2, height: '100%' }}>
      <Typography variant="h6" gutterBottom>
        Recent Activity
      </Typography>

      <List>
        {recentMessages.length === 0 ? (
          <ListItem>
            <ListItemText 
              primary="No recent activity"
              secondary="Messages will appear here once users start interacting"
            />
          </ListItem>
        ) : (
          recentMessages.map((message, index) => (
            <React.Fragment key={message.id}>
              <ListItem alignItems="flex-start">
                <ListItemAvatar>
                  <Avatar sx={{ bgcolor: 'primary.main' }}>
                    <MessageIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={message.userName || 'Anonymous'}
                  secondary={
                    <>
                      <Typography component="span" variant="body2">
                        {message.content}
                      </Typography>
                      <Typography variant="caption" display="block" color="text.secondary">
                        {message.createdAt?.toDate
                          ? moment(message.createdAt.toDate()).fromNow()
                          : 'Some time ago'}
                      </Typography>
                    </>
                  }
                />
              </ListItem>
              {index < recentMessages.length - 1 && <Divider variant="inset" component="li" />}
            </React.Fragment>
          ))
        )}
      </List>
    </Paper>
  );
};

export default RecentActivity; 